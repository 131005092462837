export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',

    // PARAMETROS MEGAPACA
    // firebase: {
    //     apiKey: "AIzaSyADC4_dUi-oB-A44CqngytuGOk-x7ctkQA",
    //     authDomain: "ianos-app-gt-megapaca-pro.firebaseapp.com",
    //     projectId: "ianos-app-gt-megapaca-pro",
    //     storageBucket: "ianos-app-gt-megapaca-pro.appspot.com",
    //     messagingSenderId: "175492524543",
    //     appId: "1:175492524543:web:348c1cafd64f5d2000dfca",
    //     measurementId: "G-0GS0GVJVFJ"
    // },
    // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    // urlBase: 'https://us-central1-ianos-app-gt-megapaca-pro.cloudfunctions.net',
    // apikey: 'a32123a80d9-1166b980f44d3-4ef0-26ac64a5',
    // urlBaseWompy: 'https://us-central1-ianos-app-gt-megapaca-pro.cloudfunctions.net/Wompi',

    // // PARAMETROS NEW ROLLING UAT
    // firebase: {
    //     apiKey: 'AIzaSyCVXxjtAV0nvITwqwHWkDH2el3SthFjP44',
    //     authDomain: 'ianos-app-new-rolling-uat.firebaseapp.com',
    //     projectId: 'ianos-app-new-rolling-uat',
    //     storageBucket: 'ianos-app-new-rolling-uat.appspot.com',
    //     messagingSenderId: '221714662002',
    //     appId: '1:221714662002:web:3b8e710323a3a167fdb8eb',
    //     measurementId: 'G-C579LV9TT0'
    // },
    // urlBase: 'https://us-central1-ianos-app-new-rolling-uat.cloudfunctions.net',
    // urlBaseWompy: 'https://us-central1-ianos-app-new-rolling-uat.cloudfunctions.net/Wompi',
    // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',

    // // TWILIO CREDENCIALES IanOS APP
    // twilio: {
    //     TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
    //     TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
    //     TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
    //     TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
    //     TWILIO_SERVICE_TOKEN: 'VA5681a04e24d8bc289d6008f39af37744'
    // },

    // // CREDENCIALES DE PRO ITWC
    // wompi: {
    //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
    //     audience: 'wompi_api', // Dejar siempre "wompi_api"
    //     client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    //     client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    // },

    // // PARAMETROS NEW ROLLING PRO
    // firebase: {
    //     apiKey: "AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI",
    //     authDomain: "newrolling-pro.firebaseapp.com",
    //     projectId: "newrolling-pro",
    //     storageBucket: "newrolling-pro.appspot.com",
    //     messagingSenderId: "883404852630",
    //     appId: "1:883404852630:web:c9496b6151d0689e0861b7",
    //     measurementId: "G-8E9QPRPXFE"
    // },

    // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
    // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
    // urlBaseNR: 'https://api.newrolling.com',
    // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',
    // googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

    // // CREDENCIALES DE PRO New Rolling
    // wompi: {
    //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
    //     audience: 'wompi_api', // Dejar siempre "wompi_api"
    //     client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    //     client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    // },

    // // TWILIO CREDENCIALES NEW ROLLING PRO
    // twilio: {
    //     TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications',
    //     TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
    //     TWILIO_AUTH_TOKEN: 'eae5d7503587447a21af4311385d376c',
    //     TWILIO_ACCOUNT_SID: 'ACe4c513b1b576a81a7304c06951362f8c',
    //     TWILIO_SERVICE_TOKEN: 'VA037cd4a1d0556aceea8d4d9524af6c24'
    // },

    // PARAMETROS Diesel Garage Grill
    firebase: {
        apiKey: "AIzaSyBIEv48ROvVIotE4RJNvlH4m05172Vdzbs",
        authDomain: "ianos-app-us-diesel-grill-pro.firebaseapp.com",
        projectId: "ianos-app-us-diesel-grill-pro",
        storageBucket: "ianos-app-us-diesel-grill-pro.appspot.com",
        messagingSenderId: "654622581184",
        appId: "1:654622581184:web:271062f5957827dc286825",
        measurementId: "G-ELTST53VCG"
    },
    googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    urlBase: 'https://us-central1-ianos-app-us-diesel-grill-pro.cloudfunctions.net',
    apikey: 'ed4c32ee793-e7c647aec3db9-4e5a-49de4764',
    urlBaseWompy: 'https://us-central1-ianos-app-new-rolling-uat.cloudfunctions.net/Wompi',

    // TWILIO CREDENCIALES IanOS APP
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
        TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
        TWILIO_SERVICE_TOKEN: 'VA5681a04e24d8bc289d6008f39af37744'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },
};

