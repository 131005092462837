import {Component, Injectable, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Injectable()
@Component({
  selector: 'app-go-back-breadcrumb',
  templateUrl: './go-back-breadcrumb.component.html',
  styleUrls: ['./go-back-breadcrumb.component.scss']
})
export class GoBackBreadcrumbComponent implements OnInit {

  constructor(
    private _location:  Location,
  ) { }

  ngOnInit(): void {
  }

  goBack() {
    this._location.back();
  }
}
