import { Injectable } from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  profileURL: Promise<any>;
  downloadURL: Observable<string>;

  constructor(
    private afStorage: AngularFireStorage,
  ) { }

  updatePhotoWeb(collectionName, file,name,id) {
    return new Promise((resolve, reject) => {
      if(file != ''){
        let n = Date.now();
        let file_path = `${collectionName}/${name[0]}_${n}_${id}.${name[1]}`;
        const fileRef = this.afStorage.ref(file_path);
        const task = this.afStorage.upload(`${collectionName}/${name[0]}_${n}_${id}.${name[1]}`, file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
          const refe = this.afStorage.storage.ref(file_path);

          refe.getDownloadURL().then((url: string) => {
            this.profileURL = snapshot.ref.getDownloadURL();
            resolve({ image: url, file_path });
          }).catch(err => {
            reject(err);
          });

        }).catch(err => {
          reject(err);
        });
      }else{
        resolve({image: '', file_path:''})
      }
    });
  }
}
