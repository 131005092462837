<div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
  <div>
<!--    <a [routerLink]="'/'+url">-->
      <h2 class="mb-3 mb-md-0">{{title}}</h2>
<!--    </a>-->
  </div>
  <div>
    <span class="cursor-pointer fs-4" (click)="goBack()">
      <i class="feather icon-arrow-left btn-icon-prepend"></i> Back
    </span>
  </div>
</div>
