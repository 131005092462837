import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {HttpService} from "../../services/http.service";
import {Observable, Subscription} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  sub1: Subscription;
  private url: string;
  private title: string;

  constructor(
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> {
    return new Promise(resolve => {
      if (this.sub1) this.sub1.unsubscribe();
      this.auth.getUser2().then(async userLocal => {
        resolve(true);
      }, (e) => {
        this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
        resolve(false);
      });
    });
  }
}
