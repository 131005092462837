import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from "../../../services/common.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string = '';
  @Input() url:   string = '';

  constructor(
    private commonService:  CommonService,
    private router:         Router,
  ) {
    this.url    = this.router.url;
    this.title  = this.url.substring(this.url.indexOf('/') + 1);
    let page    = this.title.split('/');
    this.url    = page[0].toLowerCase();
  }

  ngOnInit(): void {

  }

  goBack() {
    this.commonService.goBack();
  }
}
