import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";
import {HttpService} from "./http.service";
import {CommonService} from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private url: string;
  private title: string;
  private infoUser;
  private infoRoles;
  private page: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
    private commonService: CommonService,
  ) {
  }

  verifyRoles(menu = '') {
    return new Promise((resolve, reject) => {
      let url    = menu == '' ? this.router.url : menu;
      let title  = url.substring(url.indexOf('/') + 1);
      let page   = title.split('/');
      // console.log('url ', this.url);
      // console.log('Path ', this.title.split('/'));
      // console.log('page ', this.page[0]);
      this.auth.getUser2().then(async userLocal => {
        this.service.sendGET_OneSettingsApp("Roles").subscribe(res => {
          this.infoUser   = userLocal;
          this.infoRoles  = res;
          this.isSuperAdmin2(page).then(value => {
            resolve(value);
          });
        })
      }, (e) => {
      });
    })
  }

  private isSuperAdmin(page){
    return new Promise( (resolve, reject) => {
      let [isSuper] = this.infoRoles.admin_view.filter(value => value.user_uid == this.infoUser.uid);
      // console.log('isSuper', isSuper);
      if (isSuper && isSuper.permit) resolve(true);
      else this.roleUserCommon2(page).then(value => {
        resolve(value);
      });
    })
  }
  private isSuperAdmin2(page){
    return new Promise( (resolve, reject) => {
      let isSuper = this.infoUser.is_super;
      if (isSuper) resolve(true);
      else this.roleUserCommon2(page).then(value => {
        resolve(value);
      });
    })
  }

  private roleUserCommon(page) {
    return new Promise((resolve, reject) => {
      console.log('page', page[0])
      let [existView] = this.infoRoles.views.filter(value => value.view_name.toLowerCase() == page[0]);
      console.log('existView', existView)
      if (existView) {
        let [existsUser] = existView.users.filter(value => value.user_uid == this.infoUser.uid);
        existsUser
          ? (existsUser.permit ? resolve(true) : resolve(false))
          : resolve(false);
        console.log('existsUser', existsUser);
      } else {
        console.log('dont existsUser -> '+page[0], existView);
        // if (page[0] != 'dashboard') this.commonService.alertPermissionDenied(page[0]);
        if (page[0] != 'dashboard') resolve(false);
      }
      // console.log('existView ', existView);
    })
  }
  private roleUserCommon2(page) {
    let permissionsJSON = localStorage.getItem('menu');
    let permissions = JSON.parse(permissionsJSON);
    return new Promise((resolve, reject) => {
      let [existView] = permissions.filter(value => value.label.toLowerCase() == page[0]);
      if (existView) {
       resolve(true);
      } else {
        if (page[0] != 'dashboard') resolve(false);
      }
    })
  }



}
