import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../services/http.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public  appName: string = '';
  private settings: any;
  public  today: number = Date.now();

  constructor(

  ) {
  }

  ngOnInit(): void {
  }

}
