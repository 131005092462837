import { Injectable } from '@angular/core';
import {Observable, fromEvent, filter} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private miValorKey = "typeBusiness";
  constructor() { }

  getValorObservable(value): Observable<string> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event: StorageEvent) => event.key === value),
      map((event: StorageEvent) => event.newValue)
    );
  }

  valorAdminObservable(): Observable<string> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event: StorageEvent) => event.key === 'is_super'),
      map((event: StorageEvent) => event.newValue)
    );
  }
  currentMoneyObservable(): Observable<string> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event: StorageEvent) => event.key === 'currentMoney'),
      map((event: StorageEvent) => event.newValue)
    );
  }


}
