import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationSoundService {

  notificationSound: HTMLAudioElement;

  constructor() {
    this.notificationSound = new Audio('./assets/audio/hangouts_message.mp3');
    // Agregamos la opción de bucle
    this.notificationSound.loop = true;

    // Validamos si el archivo de audio se cargó correctamente
    if (this.notificationSound.error) {
      console.error('Error al cargar el archivo de audio:', this.notificationSound.error);
      return;
    }

    // Validamos si la ruta del archivo de audio es correcta
    if (!this.notificationSound.src) {
      console.error('Ruta del archivo de audio inválida:', this.notificationSound.src);
      return;
    }

  }

  requestPermission(): Observable<NotificationPermission> {
    return new Observable<NotificationPermission>(observer => {
      if (!('Notification' in window)) {
        console.log('entro 1');
        observer.next('default');
        observer.complete();
      } else if (Notification.permission !== 'default') {
        console.log('entro 2');
        observer.next(Notification.permission);
        observer.complete();
      } else {
        console.log('entro 3');
        Notification.requestPermission().then(permission => {
          console.log('entro 4');
          observer.next(permission);
          observer.complete();
        });
      }
      console.log('entro 5');
    });
  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  stopNotificationSound() {
    this.notificationSound.pause();
    this.notificationSound.currentTime = 0;
  }

}
