import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {EventsService} from "./events.service";
import * as firebase from 'firebase/app';
import {FirestoreService} from "./firestore.service";
import {User} from "../shared/user";
import {AngularFireAuth} from "@angular/fire/auth";
import {StorageService} from "./storage.service";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new User();
  fbSubscription: Subscription;
  fsSubscription: Subscription;
  modelWatcher = new BehaviorSubject(new User());
  count: number = 0;

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    private storage: StorageService,
    private events: EventsService,
    private service: HttpService,
  ) {
  }

  public getUser2(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.user.uid !== "") {
        // console.log('auth getUserLocal() : ', this.user);
        resolve(this.user)
      } else {
        if (this.fbSubscription) {
          this.fbSubscription.unsubscribe();
        }
        this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
          if (userFirebase) {
            // console.log('auth getUserFirebase() : ', userFirebase);
            /*this.service.getOneUser(userFirebase.uid).subscribe( userLocal => {
              this.user = userLocal;
              console.log('getOneUser : ', userLocal);
              resolve(userLocal);
            })*/

            this.firestore.get('Users/' + userFirebase.uid).then(ref => {
              // console.log('auth get() : ', ref);

              if (this.fsSubscription) {
                this.fsSubscription.unsubscribe();
              }
              this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                this.user = userLocal;
                // console.log('auth getUserFirestore() : ', userLocal);
                resolve(userLocal);
              });
            }).catch((e) => {
              reject(e);
            });
          } else {
            reject(false);
          }
        });
      }
    });
  }

  public registerWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
        // console.log('1 - createUserWithEmailAndPassword res: ', res);
        resolve(res);
        // console.log('create registerWithEmail');
        this.sendEmailVerification();
      }).catch(err => {
        reject(err);
      });
    });
  }

  public loginWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
        /*    JSON.stringify(res);  */
        resolve(res);
        // console.log('loginWithEmail');
      }).catch(err => {
        reject(err);
      });
    });
  }

  public sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(user => {
        user.sendEmailVerification().then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    });
  }

  // Reset password of the logged in user on Firebase.
  public resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public updatePassword(newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.currentUser.updatePassword(newPassword)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updatePassword2(currentPassword: string, newPassword: string): Promise<any> {
    // Creamos la credencial de autenticación para verificar la contraseña actual del usuario.
    const credential = firebase.auth.EmailAuthProvider.credential(
      this.afAuth.auth.currentUser.email,
      currentPassword
    );

    // Verificamos la credencial de autenticación antes de actualizar la contraseña del usuario.
    return this.afAuth.auth.currentUser.reauthenticateWithCredential(credential)
      .then(() => {
        // Si la verificación es exitosa, actualizamos la contraseña del usuario.
        return this.afAuth.auth.currentUser.updatePassword(newPassword);
      })
      .catch((err) => {
        // Si la verificación falla, rechazamos la promesa con el error correspondiente.
        throw new Error('La verificación de la contraseña actual ha fallado.');
      });
  }

  /*public updateEmail(newEmail: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.currentUser.updateEmail(newEmail)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }*/

  public updateEmail(newEmail: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Creamos una instancia del proveedor de autenticación de Google.
      const provider = new firebase.auth.GoogleAuthProvider();

      // Solicitamos al usuario que vuelva a autenticarse con su cuenta de Google.
      this.afAuth.auth.currentUser.reauthenticateWithPopup(provider)
        .then(() => {
          // Si la autenticación es exitosa, actualizamos el correo electrónico del usuario.
          this.afAuth.auth.currentUser.updateEmail(newEmail)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updatePhoneNumber(phoneNumber: string): Promise<any> {
    // Creamos una instancia del objeto firebase.auth.PhoneAuthProvider.
    const provider = new firebase.auth.PhoneAuthProvider();

    // Solicitamos el código de verificación al número de teléfono proporcionado.
    return provider.verifyPhoneNumber(phoneNumber, new firebase.auth.RecaptchaVerifier('recaptcha-container'))
      .then((verificationId) => {
        // Al recibir el código de verificación, creamos la credencial de autenticación.
        const verificationCode = window.prompt('Por favor, ingrese el código de verificación que se le ha enviado a su teléfono:');
        const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);

        // Vinculamos el número de teléfono a la cuenta de usuario existente.
        return this.afAuth.auth.currentUser.updatePhoneNumber(credential);
      })
      .catch((err) => {
        // Si hay algún error, rechazamos la promesa con el error correspondiente.
        throw new Error('Ha ocurrido un error al vincular el número de teléfono a la cuenta de usuario.');
      });
  }

  public logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut().then((e) => {
        localStorage.setItem('user', null);
        localStorage.setItem('orders', null);
        localStorage.setItem('lastBuy', null);
        // this.storage.remove('uid');
        //this.facebook.logout();
        //this.googlePlus.logout();
        this.events.publish('session-start', false);
        // console.log(e);
        this.user = null;
        // console.log('logout() true');
        resolve(true);
      }).catch((e) => {
        // console.log(e);
        // console.log('logout() false');
        reject(e);
      });
    });
  }

  public registerWithNumberPhone(phoneNumber): Promise<any> {
    return new Promise((resolve, reject) => {
      let appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          // size: "invisible",
          callback: (response) => {

          }
        }
      );
      let phoneCredential;
      let credential;
      let provider = new firebase.auth.PhoneAuthProvider();
      provider.verifyPhoneNumber(phoneNumber, appVerifier)
        .then(function (verificationId) {
          let verificationCode = window.prompt('Please enter the verification ' +
            'code that was sent to your mobile device.');
          credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
          phoneCredential = firebase.auth().signInWithCredential(credential);
          // firebase.auth().currentUser.updatePhoneNumber(phoneCredential)
          // user.currentUser.updatePhoneNumber(phoneCredential);
        })
        .then((result) => {
          // Phone credential now linked to current user.
          // User now can sign in with new phone upon logging out.
          console.log("Success update phone", phoneCredential);
          resolve({phoneCredential, credential});
        })
        .catch((error) => {
          // Error occurred.
          console.log(error);
          reject(error);
        });
    });
  }
}
